export let nanoid = (t = 21) => {

  if (!globalThis.crypto) {
    const getRandomValues = (t: Uint8Array) => {
      for (let e = 0; e < t.length; e++) {
        t[e] = Math.floor(256 * Math.random());
      }
      return t;
    };
    return getRandomValues(new Uint8Array(t)).reduce(
      (t, e) =>
        (t +=
          (e &= 63) < 36
            ? e.toString(36)
            : e < 62
            ? (e - 26).toString(36).toUpperCase()
            : e > 62
            ? "-"
            : "_"),
      ""
    );
  }
  return globalThis.crypto
    .getRandomValues(new Uint8Array(t))
    .reduce(
      (t, e) =>
        (t +=
          (e &= 63) < 36
            ? e.toString(36)
            : e < 62
            ? (e - 26).toString(36).toUpperCase()
            : e > 62
            ? "-"
            : "_"),
      ""
    );
};
